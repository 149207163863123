




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../../entity/EntityCrud.vue";
import blogPostCategoryAdminModel from "@/api/blog_post_category.admin.model";

@Component({
	components: { EntityCrud },
})
export default class BlogPostCategories extends Vue {
	model: any = blogPostCategoryAdminModel;

	title = "Categorias de publicações";
	tableColumns = ["name"];
	filter = {};

	formColumns = ["name"];
	defaultEntity = {};
}
